import React from 'react'
import "./Footer.scss";
const Footer = () => {
  return (
    <div className="footer">
    <p>Powered by <a href="https://www.spotify.com">Spotify</a> Created by <a href="https://www.linkedin.com/in/alvarocastelan/">Alvaro Castelan.</a></p>
      
    </div>
  )
}

export default Footer
